<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="contenedor-gral">
                    <div class="logo">
                        <img src="../assets/img/logo-blanco.svg">
                    </div>
                    <div class="info">
                        <div class="menu">
                            <ul>
                                <li class="item"><router-link to="/">Home</router-link></li>
                                <li class="item"><router-link to="/">Residential</router-link></li>
                                <li class="item"><router-link to="/">Commercial & Industrial</router-link></li>
                                <li class="item"><router-link to="/">Maintenance & Repair</router-link></li>
                                <li class="item"><router-link to="/">Service Areas</router-link></li>
                            </ul>
                        </div>
                        <div class="redes">
                            <div class="info-contac">
                                Houston TX 77073, (+1) 713 240 5927 <br>
                                Monday to Friday: 8:00 a.m. to 6:00 p.m.
                                Saturday: 8:00 a.m. to 1:00 p.m.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <span>CENTURION</span> | <span>Overhead Doors Services</span> | 2024
        </div>
    </footer>
</template>

<script>


export default {
    name: 'compFooter',
    components: {
    },
    data() {
        return {
        };
    },
};
</script>