<template>
    <section id="header-horario">
        <div class="container">
            <div class="row">
                <div class="col desc">
                    <i class="fa-solid fa-clock"></i>
                    <span>Working hours:</span>
                    Monday to Friday: 8:00 a.m. to 6:00 p.m.
                    Saturday: 8:00 a.m. to 1:00 p.m.
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'compSeccHora',
    components: {
    },
    data() {
        return {
        };
    },
};
</script>
