import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueRouter from 'vue-router'
import VModal from "vue-js-modal";


Vue.use(VModal);

Vue.config.productionTip = false

Vue.use(VueRouter)


import inicio from './inicio.vue'
import contac from './contac.vue'
import residential from './components/residential/residential.vue'
import sectional from './components/comercial/sectional.vue'
import rollup from './components/comercial/roll-up.vue'
import rollers from './components/repair/rollers.vue'
import broken from './components/repair/broken.vue'
import cable from './components/repair/cable.vue'
import maintenance from './components/repair/maintenance.vue'
import panels from './components/repair/panels.vue'
import openers from './components/openers/openers.vue'

const routes = [                                    
  { path: '/', component: inicio },                       
  { path: '/contac', component: contac },  
  { path: '/residential', component: residential }, 
  { path: '/sectional', component: sectional }, 
  { path: '/roll-up', component: rollup }, 
  { path: '/rollers', component: rollers },
  { path: '/broken', component: broken },
  { path: '/cable', component: cable },
  { path: '/maintenance', component: maintenance },
  { path: '/panels', component: panels },
  { path: '/openers', component: openers },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

new Vue({
  render: h => h(App),
  router
}).$mount('#app')