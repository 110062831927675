<template>
    <div>
        <compHeader :activoMenu="activoMenu"></compHeader>
        
        
    <section id="informacion">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titulo-pricipal">
                        <img src="../../assets/img/logo-icono-color.svg">
                        <span class="azul">Maintenance </span>
                    </div>
                    <div class="intro">
                        Preventive Maintenance Service for Residential and Commercial Garage Doors
                    </div>
                </div>
                <div class="col-12 text-welcom">
                    Centurion Overhead Doors takes pride in offering a comprehensive preventive maintenance service for garage doors, catering to both residential and commercial clients in Houston, Texas. Our goal is to ensure optimal performance, durability, and ongoing safety for your garage doors.
                </div>



                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residencial-01.jpeg"></div>
                            <div class="titulo">Comprehensive Inspection</div>
                            <div class="desc">
                                Our highly trained technicians conduct a thorough inspection of your sectional garage door to identify any wear, damage, or components that may need adjustments.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residencial-01.jpeg"></div>
                            <div class="titulo">Lubrication and Adjustments</div>
                            <div class="desc">
                                We apply high-quality lubricants to key components to reduce friction and ensure smooth operation. Precise adjustments are made to guarantee that the door travels correctly along the tracks.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residencial-01.jpeg"></div>
                            <div class="titulo">Tightening of Screws and Nuts</div>
                            <div class="desc">
                                We check and tighten all screws and nuts to prevent issues arising from loose parts.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residencial-01.jpeg"></div>
                            <div class="titulo">Spring System Verification</div>
                            <div class="desc">
                                We review the spring system to ensure it is balanced and functions properly. Imbalanced springs can cause serious issues and compromise safety.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residencial-01.jpeg"></div>
                            <div class="titulo">Safety Testing</div>
                            <div class="desc">
                                We perform comprehensive tests on safety devices such as obstruction sensors and reversal systems to ensure they respond correctly.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residencial-01.jpeg"></div>
                            <div class="titulo">Panel Inspection and Part Replacement</div>
                            <div class="desc">
                                We visually inspect panels for dents, cracks, or other damage, and replace defective parts if necessary.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="col-12 preguntas">
                    <div class="titulo-sec">
                        <i class="fa-solid fa-circle-question"></i>
                        Frequently Asked Questions
                    </div>
                    <div class="item-pre uno">
                        <div class="pre" @click="togglePre(1)">
                            <span>How often should preventive maintenance be performed on a garage door?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preUno, 'fa-chevron-down' : !preUno }"></i>
                        </div>
                        <div class="res" v-show="preUno">
                            We recommend performing preventive maintenance at least once a year to ensure continuous operation and prevent future issues.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(2)">
                            <span>How long does it take to perform preventive maintenance service?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preDos, 'fa-chevron-down' : !preDos }"></i>
                        </div>
                        <div class="res" v-show="preDos">
                            The time can vary, but generally takes between one and two hours, depending on the complexity of the door and the need for additional repairs.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(3)">
                            <span>What are the benefits of preventive maintenance?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preTres, 'fa-chevron-down' : !preTres }"></i>
                        </div>
                        <div class="res" v-show="preTres">
                            Preventive maintenance helps prevent unexpected breakdowns, extends the lifespan of the door, improves efficiency, and maintains high safety standards.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(4)">
                            <span>What should I do if I notice a problem with my garage door between maintenance services?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preCuatro, 'fa-chevron-down' : !preCuatro }"></i>
                        </div>
                        <div class="res" v-show="preCuatro">
                            We recommend contacting our customer service team as soon as possible to schedule an evaluation and possible repairs.
                        </div>
                    </div>
                </div>
                <div class="col-12 pie">
                    Centurion Overhead Doors is committed to providing exceptional preventive maintenance service for sectional garage doors, offering peace of mind to our customers in Houston, Texas.
                </div>
            </div>
        </div>
    </section>
    <compFooter></compFooter>
    </div>
</template>
  
<script>

import compHeader from '../header.vue'
import compFooter from '../footer.vue'
import compRedes from '../redes.vue'

export default {
    name: 'compMaintenance',
    components: {
        compHeader,
        compFooter,
        compRedes,
    },
    data() {
        return {
            activoMenu: 'repair',
            preUno: false, 
            preDos: false, 
            preTres: false, 
            preCuatro: false, 
            preCinco: false, 
        };
    },
    methods: {
        togglePre(index) {
            this.preUno = index === 1 ? !this.preUno : false;
            this.preDos = index === 2 ? !this.preDos : false;
            this.preTres = index === 3 ? !this.preTres : false;
            this.preCuatro = index === 4 ? !this.preCuatro : false;
            this.preCinco = index === 5 ? !this.preCinco : false;
        }
    }
}
</script>