<template>
    <div id="chat" :style="{ display: showButton ? 'block' : 'none' }">
        <div class="cont-gral">
            <div class="icono-principal"><a href="#top"><i class="fa-solid fa-circle-up fa-bounce"></i></a></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'compChat',
    components: {
    },
    data() {
        return {
            showButton: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            // Verifica la posición actual de desplazamiento
            const scrollPosition = window.scrollY || window.pageYOffset;

            // Decide si mostrar o no el botón basado en la posición de desplazamiento
            this.showButton = scrollPosition > 200;
        },
    },
};
</script>
