<template>
    <div>
        <compHeader :activoMenu="activoMenu"></compHeader>
        
        
    <section id="informacion">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titulo-pricipal">
                        <img src="../../assets/img/logo-icono-color.svg">
                        <span class="azul">Rollers And Tracks Off</span>
                    </div>
                    <!-- <div class="intro">
                        <strong>Welcome to CENTURION overhead doors:</strong> Elevating the Residential Experience with
                        Our Garage Door and Openers Services
                    </div> -->
                </div>
                <div class="col-12 text-welcom">
                    Centurion Overhead Doors offers a specialized service for the repair or replacement of rollers and tracks for residential and commercial garage doors in Houston, Texas. This service focuses on addressing common issues such as wear, corrosion, or malfunction of rollers and tracks, ensuring optimal performance of the garage door.
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/rollers/rollers-1.jpg"></div>
                            <div class="titulo">Professional Diagnosis</div>
                            <div class="desc">
                                Our highly trained technicians will conduct a comprehensive diagnosis to identify the cause of the issue with the garage door rollers and tracks, ensuring a precise solution.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/rollers/rollers-2.jpg"></div>
                            <div class="titulo">Quality Repair</div>
                            <div class="desc">
                                We perform repairs using high-quality spare parts to ensure durability and smooth operation of the garage door.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/rollers/rollers-3.jpg"></div>
                            <div class="titulo">Efficient Replacement</div>
                            <div class="desc">
                                In cases where repair is not feasible, we offer efficient and professional replacement services using top-quality products.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/rollers/rollers-4.jpg"></div>
                            <div class="titulo">Adjustments and Lubrication</div>
                            <div class="desc">
                                In addition to repair or replacement, we make necessary adjustments and apply specialized lubricants to ensure a smooth movement of rollers and tracks.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 preguntas">
                    <div class="titulo-sec">
                        <i class="fa-solid fa-circle-question"></i>
                        Frequently Asked Questions
                    </div>
                    <div class="item-pre uno">
                        <div class="pre" @click="togglePre(1)">
                            <span>How do I know if my rollers and tracks need repair or replacement?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preUno, 'fa-chevron-down' : !preUno }"></i>
                        </div>
                        <div class="res" v-show="preUno">
                            Common signs include unusual noises, resistance when opening or closing the door, and misalignment of the tracks. If you experience any of these issues, it is advisable to request a professional assessment.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(2)">
                            <span>How long does the repair or replacement service take?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preDos, 'fa-chevron-down' : !preDos }"></i>
                        </div>
                        <div class="res" v-show="preDos">
                            The time can vary depending on the severity of the issue. Our technicians will work efficiently to complete the service as quickly as possible, minimizing inconvenience.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(3)">
                            <span>How long will the repairs or replacement last?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preTres, 'fa-chevron-down' : !preTres }"></i>
                        </div>
                        <div class="res" v-show="preTres">
                            We guarantee the quality of our repairs and replacements, and the duration will depend on regular maintenance and proper use of the garage door.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(4)">
                            <span>What is the cost of the service?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preCuatro, 'fa-chevron-down' : !preCuatro }"></i>
                        </div>
                        <div class="res" v-show="preCuatro">
                            Costs may vary depending on the extent of repairs or the type of replacement needed. We provide transparent estimates before commencing any work.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <compFooter></compFooter>
    </div>
</template>
  
<script>

import compHeader from '../header.vue'
import compFooter from '../footer.vue'
import compRedes from '../redes.vue'

export default {
    name: 'compRollers',
    components: {
        compHeader,
        compFooter,
        compRedes,
    },
    data() {
        return {
            activoMenu: 'repair',
            preUno: false, 
            preDos: false, 
            preTres: false, 
            preCuatro: false, 
            preCinco: false, 
        };
    },
    methods: {
        togglePre(index) {
            this.preUno = index === 1 ? !this.preUno : false;
            this.preDos = index === 2 ? !this.preDos : false;
            this.preTres = index === 3 ? !this.preTres : false;
            this.preCuatro = index === 4 ? !this.preCuatro : false;
            this.preCinco = index === 5 ? !this.preCinco : false;
        }
    }
}
</script>