<template>
    <div>
        <compHeader :activoMenu="activoMenu"></compHeader>
        
        
    <section id="informacion">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titulo-pricipal">
                        <img src="../../assets/img/logo-icono-color.svg">
                        <span class="azul">Residential Garage Doors </span>
                    </div>
                    <!-- <div class="intro">
                        <strong>Welcome to CENTURION overhead doors:</strong> Elevating the Residential Experience with
                        Our Garage Door and Openers Services
                    </div> -->
                </div>
                <div class="col-12 text-welcom">
                    The service of selling and installing residential steel, insulated, and glass garage doors from Centurion Overhead Doors in Houston, Texas, is distinguished by providing a diverse range of high-quality and customizable solutions for homes in the area. The company specializes in offering garage doors that not only prioritize durability and security, as with steel doors, but also incorporate insulation and glass elements for enhanced functionality and aesthetic appeal.
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residensial/residensial-1.jpg"></div>
                            <div class="titulo">Steel Garage Doors</div>
                            <div class="desc">
                                Centurion Overhead Doors offers robust garage doors constructed from high-quality steel, ensuring durability and long-lasting performance. These doors provide a strong protective barrier while maintaining a sleek and modern appearance.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residensial/residensial-2.jpg"></div>
                            <div class="titulo">Insulated Garage Doors</div>
                            <div class="desc">
                                The company provides insulated garage doors designed to enhance energy efficiency and climate control within the garage space. This feature is particularly beneficial for residences in Houston, helping to regulate temperatures and potentially reduce energy costs.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residensial/residensial-9.jpg"></div>
                            <div class="titulo">Glass Garage Doors</div>
                            <div class="desc">
                                Centurion Overhead Doors offers garage doors with glass elements, adding a contemporary and visually appealing touch to residential properties. Glass doors allow natural light into the garage space, creating a bright and inviting atmosphere.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residensial/residensial-4.jpg"></div>
                            <div class="titulo">Variety of Designs and Finishes</div>
                            <div class="desc">
                                Customers have the option to choose from a variety of designs, finishes, and styles for their garage doors. This customization allows homeowners to select doors that complement the architecture and aesthetic preferences of their residences.
We work with the following partner brands to offer a wide range of options to our customers.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residensial/residensial-5.jpg"></div>
                            <div class="titulo">Professional Installation</div>
                            <div class="desc">
                                The installation team at Centurion Overhead Doors consists of trained professionals skilled in installing steel, insulated, and glass garage doors. This ensures precise and secure installation for optimal functionality.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residensial/residensial-6.jpg"></div>
                            <div class="titulo">Automation and Technology</div>
                            <div class="desc">
                                The company offers automation options for garage doors, allowing for convenient opening and closing. Additionally, modern technologies, such as remote control systems and smart features, can be integrated for enhanced user convenience.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residensial/residensial-7.jpg"></div>
                            <div class="titulo">Customized Customer Service</div>
                            <div class="desc">
                                Centurion Overhead Doors takes pride in providing personalized customer service, assisting clients in choosing garage doors that align with their specific requirements and preferences. From selection to installation, the company aims to offer a tailored experience.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/residensial/residensial-8.jpg"></div>
                            <div class="titulo">Regulatory Compliance</div>
                            <div class="desc">
                                All garage doors offered by Centurion Overhead Doors comply with local regulations and standards concerning construction and safety.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 pie">
                    By combining these features, Centurion Overhead Doors aims to position itself as a comprehensive and reliable choice for the sale and installation of residential steel, insulated, and glass garage doors in the Houston, Texas community.
                </div> 
            </div>
        </div>
    </section>

        <compFooter></compFooter>
    </div>
</template>
  
<script>

import compHeader from '../header.vue'
import compFooter from '../footer.vue'
import compRedes from '../redes.vue'

export default {
    name: 'compResidencial',
    components: {
        compHeader,
        compFooter,
        compRedes,
    },
    data() {
        return {
            activoMenu: 'residential',
        };
    },
}
</script>