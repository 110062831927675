<template>
    <div>
        <compHeader :activoMenu="activoMenu"></compHeader>
        
        
    <section id="informacion">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titulo-pricipal">
                        <img src="../../assets/img/logo-icono-color.svg">
                        <span class="azul">Sectional Overhead Doors</span>
                    </div>
                    <!-- <div class="intro">
                        <strong>Welcome to CENTURION overhead doors:</strong> Elevating the Residential Experience with
                        Our Garage Door and Openers Services
                    </div> -->
                </div>
                <div class="col-12 text-welcom">
                    The service of selling and installing commercial steel and insulated sectional garage doors from Centurion Overhead Doors in Houston, Texas, is distinguished by providing high-quality and energy-efficient solutions for commercial properties in the area. Centurion Overhead Doors specializes in offering a range of commercial garage doors that not only meet stringent security standards but also provide insulation for improved energy efficiency.
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/sectional/sectional-1.jpg"></div>
                            <div class="titulo">Commercial-Grade Steel Doors</div>
                            <div class="desc">
                                Centurion Overhead Doors provides durable, commercial-grade steel garage doors suitable for various commercial properties. These doors are designed to withstand heavy usage and provide robust security for businesses.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/sectional/sectional-2.jpg"></div>
                            <div class="titulo">Insulation for Energy Efficiency</div>
                            <div class="desc">
                                The company offers insulated sectional garage doors that provide enhanced energy efficiency. This insulation helps regulate interior temperatures, making it ideal for commercial spaces where climate control is crucial, potentially leading to cost savings on heating and cooling.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/sectional/sectional-3.jpg"></div>
                            <div class="titulo">Customizable Designs</div>
                            <div class="desc">
                                Centurion Overhead Doors offers a variety of customizable designs to suit the aesthetic preferences and functional requirements of different commercial establishments. Clients can choose from different finishes, styles, and sizes to meet their specific needs.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/sectional/sectional-4.jpg"></div>
                            <div class="titulo">Professional Installation Services</div>
                            <div class="desc">
                                The installation team at Centurion Overhead Doors is composed of trained professionals experienced in installing commercial garage doors. They ensure precise installation, adhering to safety standards and guidelines.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/sectional/sectional-5.jpg"></div>
                            <div class="titulo">Security and Access Control Features</div>
                            <div class="desc">
                                The commercial garage doors may include advanced security features and access control options, such as keyless entry systems or integration with security systems, providing an extra layer of protection for businesses.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/sectional/sectional-6.jpg"></div>
                            <div class="titulo">Automation and Technology Integration</div>
                            <div class="desc">
                                Centurion Overhead Doors offers automation options for commercial garage doors, providing convenient and efficient operation. Integration with modern technologies, such as remote control systems and smart building platforms, can enhance overall functionality.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/sectional/sectional-7.jpg"></div>
                            <div class="titulo">Regulatory Compliance</div>
                            <div class="desc">
                                All commercial garage doors from Centurion Overhead Doors comply with relevant local regulations and safety standards applicable to commercial properties.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option hidden-xs">
                    </div>
                </div>
                <div class="col-12 pie">
                    By offering these features, Centurion Overhead Doors aims to establish itself as a reliable and specialized choice for the sale and installation of steel and insulated sectional garage doors tailored for commercial use in the Houston, Texas area.
                </div>
            </div>
        </div>
    </section>

        <compFooter></compFooter>
    </div>
</template>
  
<script>

import compHeader from '../header.vue'
import compFooter from '../footer.vue'
import compRedes from '../redes.vue'

export default {
    name: 'compComercial',
    components: {
        compHeader,
        compFooter,
        compRedes,
    },
    data() {
        return {
            activoMenu: 'residential',
        };
    },
}
</script>