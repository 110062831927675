var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"header-contacto"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._m(0),_c('div',{staticClass:"contacto"},[_c('div',{staticClass:"ubicacion",on:{"click":_vm.openGoogleMaps}},[_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"telefono",on:{"click":_vm.makePhoneCall}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"whats hidden-xs"},[_c('div',{staticClass:"cont-whats",on:{"click":_vm.openWhatsApp}},[_vm._m(5),_vm._m(6)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../assets/img/logo.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-location-dot"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('div',{staticClass:"tit hidden-xs"},[_vm._v("You can find us at")]),_c('div',{staticClass:"ubi"},[_vm._v("Houston TX 77073")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-phone"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('div',{staticClass:"tit hidden-xs"},[_vm._v("Do you need services? ")]),_c('div',{staticClass:"tel"},[_vm._v("(+1) 713 240 5927")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icono"},[_c('i',{staticClass:"fa-brands fa-square-whatsapp"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('span',{staticClass:"uno"},[_vm._v("CHAT")]),_c('span',{staticClass:"dos"},[_vm._v("WITH US")])])
}]

export { render, staticRenderFns }