<template>
    <section id="info-inicio">
        <div class="quienes-somos">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="titulo-pricipal">
                            <img src="../assets/img/logo-icono-color.svg">
                            <span class="azul">About </span>
                            <span class="rojo">Us</span>
                        </div>
                        <div class="intro">
                            <strong>CENTURION overhead doors:</strong> Recent Innovation, Solid Experience in garage Door
                            and
                            Openers Services
                            for Businesses and Residences.
                        </div>
                    </div>
                </div>
                <div class="mt-5 quienes">
                    <div class="img-quienes">
                        <img src="../assets/img/quienes.jpg">
                    </div>
                    <div class="info-quienes">
                        At <strong>CENTURION</strong> overhead doors, we combine the freshness of a newly created company
                        with
                        the strength of
                        extensive experience in the field of garage Door and Opener. We are a leading company in sale,
                        installation and repair of garage Door and Openers in Houston TX area. Although we are new to the
                        scene,
                        our team brings with them years of knowledge and skill, offering services for both commercial and
                        residential environments. We are proud to highlight our personalized attention, an unparalleled
                        commitment to quality and the exceptionality of not using third parties in any aspect of our
                        services.
                    </div>
                </div>
            </div>
        </div>

        <div class="porque">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="titulo-pricipal">
                            <img src="../assets/img/logo-icono-color.svg">
                            <span class="azul">Why Choose Us </span>
                            <span class="rojo">?</span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 intro">
                        <p>Join the <strong>CENTURION</strong> Experience: We invite you to join the
                            <strong>CENTURION</strong> overhead
                            doors experience.
                            Find out how we can transform your commercial or residential garage door with solutions that not
                            only meet your expectations but exceed them.
                        </p>
                        <p>Discover <strong>CENTURION</strong> overhead doors, where tradition meets innovation, and each
                            project is an
                            expression of our commitment to quality and unparalleled attention.</p>

                    </div>
                </div>

                <div class="row porque">
                    <div class="col-md-4 item-porque">
                        <div class="img">
                            <img src="../assets/img/quienes-01.jpg">
                        </div>
                        <div class="info">
                            <div class="titulo">
                                <img src="../assets/img/logo-icono-color.svg">
                                <span>24/7 Service</span>
                            </div>
                            <div class="desc">
                                We take emergency calls in any critical situation with your garage door. We offer quick
                                support so that you can have your garage door up and running as quickly as possible,
                                ensuring safe and efficient operation.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 item-porque">
                        <div class="img">
                            <img src="../assets/img/quienes-02.jpg">
                        </div>
                        <div class="info">
                            <div class="titulo">
                                <img src="../assets/img/logo-icono-color.svg">
                                <span>100% personalized attention</span>
                            </div>
                            <div class="desc">
                                We are a family-run company, we do not have pushy salespeople, so you can be confident
                                of receiving a cordial and friendly treatment. We will answer your queries and provide
                                you with clear information from our extensive experience for you to decide.
                            </div>
                        </div>

                    </div>
                    <div class="col-md-4 item-porque">

                        <div class="img">
                            <img src="../assets/img/quienes-03.jpg">
                        </div>
                        <div class="info">
                            <div class="titulo">
                                <img src="../assets/img/logo-icono-color.svg">
                                <span>Professional Services</span>
                            </div>
                            <div class="desc">
                                Our experience allows us to offer a wide variety of garage door styles and options.
                                High-quality, durable and resistant products, as well as a guaranteed correct
                                installation. Always offering the most competitive prices on the market.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="servicios">
            <div class="container-fluid">
                <div class="row">
                    <div class="cont-servicios">
                        <router-link to="/residential" class="residencial">
                            <div class="info">
                                <div class="titulo">Residential</div>
                                <div class="desc">
                                    Elevating the Residential Experience with Our Garage Door and Openers Services
                                </div>
                            </div>
                        </router-link>
                        <router-link to="/commercial" class="industria">
                            <div class="info">
                                <div class="titulo">Commercial & Industrial</div>
                                <div class="desc">
                                    Expert Solutions for Commercial & Industrial Garage Doors & Openers
                                </div>
                            </div>
                        </router-link>
                        <router-link to="/maintenance" class="mantenimiento">
                            <div class="info">
                                <div class="titulo">Maintenance & Repair</div>
                                <div class="desc">
                                    Guaranteeing Residential and Commercial Functionality and Security
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>


export default {
    name: 'compInfoInicio',
    components: {
    },
    data() {
        return {
        };
    },
};
</script>