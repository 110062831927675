<template>
    <div>
        <compHeader :activoMenu="activoMenu"></compHeader>
        
        
    <section id="informacion">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titulo-pricipal">
                        <img src="../../assets/img/logo-icono-color.svg">
                        <span class="azul">Garage Door Openers</span>
                    </div>
                    <!-- <div class="intro">
                        <strong>Welcome to CENTURION overhead doors:</strong> Elevating the Residential Experience with
                        Our Garage Door and Openers Services
                    </div> -->
                </div>
                <div class="col-12 text-welcom">
                    The service of selling and installing residential and commercial garage door openers from Centurion Overhead Doors in Houston, Texas, stands out for offering efficient and technologically advanced solutions to meet the needs of both homes and businesses. The company specializes in providing garage door openers that ensure convenience, security, and reliability.
                </div>



                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/garage/garage-1.jpg"></div>
                            <div class="titulo">Residential and Commercial Openers</div>
                            <div class="desc">
                                Centurion Overhead Doors offers a wide range of openers for both residential and commercial garage doors. These openers are designed to accommodate different sizes and weights of doors, providing customized solutions for each customer. 
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/garage/garage-2.jpg"></div>
                            <div class="titulo">Advanced Technology</div>
                            <div class="desc">
                                Centurion Overhead Doors' openers incorporate advanced technologies to ensure smooth and efficient operation. Features such as remote control, Wi-Fi connectivity, and innovative security systems are available to enhance the user experience.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/garage/garage-3.jpg"></div>
                            <div class="titulo">Professional Installation</div>
                            <div class="desc">
                                The installation team at Centurion Overhead Doors consists of professionals trained in the installation of garage door openers. They ensure precise and secure placement, guaranteeing optimal opener performance.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/garage/garage-4.jpg"></div>
                            <div class="titulo">Compatibility and Customization</div>
                            <div class="desc">
                                Openers can be customized to the specific needs of customers and are compatible with a variety of garage door models and brands. This provides flexibility for customers when choosing the opener that best suits their requirements.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/garage/garage-5.jpg"></div>
                            <div class="titulo">Comprehensive Service</div>
                            <div class="desc">
                                Centurion Overhead Doors offers a comprehensive service that includes sales, installation, and, if necessary, maintenance of openers. This ensures a hassle-free experience for customers from product selection to continuous operation.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option hidden-xs">
                    
                    </div>
                </div>



                <div class="col-12 preguntas">
                    <div class="titulo-sec">
                        <i class="fa-solid fa-circle-question"></i>
                        Frequently Asked Questions
                    </div>
                    <div class="item-pre uno">
                        <div class="pre" @click="togglePre(1)">
                            <span>What is the difference between a residential opener and a commercial one?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preUno, 'fa-chevron-down' : !preUno }"></i>
                        </div>
                        <div class="res" v-show="preUno">
                            Residential openers are designed for lighter use and have specific capabilities for homes, while commercial openers are built for more intensive use and can handle larger and heavier doors.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(2)">
                            <span>Can I control my garage door opener from my mobile phone?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preDos, 'fa-chevron-down' : !preDos }"></i>
                        </div>
                        <div class="res" v-show="preDos">
                            Yes, many of our openers feature Wi-Fi technology that allows remote control through mobile applications, providing convenience and flexibility.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(3)">
                            <span>How long does it take to install a garage door opener?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preTres, 'fa-chevron-down' : !preTres }"></i>
                        </div>
                        <div class="res" v-show="preTres">
                            Installation time varies depending on the type of opener and specific features of the garage door, but our team strives to complete the installation efficiently, usually within a few hours.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <compFooter></compFooter>
    </div>
</template>
  
<script>

import compHeader from '../header.vue'
import compFooter from '../footer.vue'
import compRedes from '../redes.vue'

export default {
    name: 'compOpeners',
    components: {
        compHeader,
        compFooter,
        compRedes,
    },
    data() {
        return {
            activoMenu: 'repair',
            preUno: false, 
            preDos: false, 
            preTres: false, 
            preCuatro: false, 
            preCinco: false, 
        };
    },
    methods: {
        togglePre(index) {
            this.preUno = index === 1 ? !this.preUno : false;
            this.preDos = index === 2 ? !this.preDos : false;
            this.preTres = index === 3 ? !this.preTres : false;
            this.preCuatro = index === 4 ? !this.preCuatro : false;
            this.preCinco = index === 5 ? !this.preCinco : false;
        }
    }
}
</script>