<template>
    <div>
        <compHeader :activoMenu="activoMenu"></compHeader>
        <compContacto></compContacto>
        <compFooter></compFooter>
    </div>
</template>
  
<script>

import compHeader from './components/header.vue'
import compContacto from './components/contacto.vue'
import compFooter from './components/footer.vue'

export default {
    name: 'App',
    components: {
        compHeader,
        compContacto,
        compFooter,
    },
    data() {
        return {
            activoMenu: 'contac',
        };
    },
}
</script>