<template>
    <section id="carrusel">
        <div class="trans"></div>
        <div class="cont-carrusel">
            <transition name="fade" mode="out-in">
                <img :key="currentImage.src" :src="currentImage.src" :alt="currentImage.description">
            </transition>
            <div class="texto">
                <div class="logo"><img src="../assets/img/logo-icono-blanco.svg"></div>
                <div class="titulo">{{ currentImage.titulo }}</div>
                <div class="descripcion">
                    {{ currentImage.description }}
                </div>
                <div class="botones">
                    <div class="btn-mas">
                        <router-link to="/"><i class="fa-regular fa-file-lines"></i> More information</router-link>
                    </div>
                    <div class="btn-whats" @click="openWhatsApp">
                        <i class="fa-brands fa-whatsapp"></i>Chat With Us
                    </div>
                </div>
            </div>
            <div class="bullets hidden-xs">
                <span v-for="(image, index) in images" :key="index" @click="changeImage(index)"
                    :class="{ active: index === currentIndex }">
                    <i class="fa-solid fa-circle"></i></span>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'compCarrusel',
    data() {
        return {
            images: [
                { src: require('../assets/img/banner-01.jpg'), titulo: 'Residential', description: 'Elevating the Residential Experience with Our Garage Door and Openers Services' },
                { src: require('../assets/img/banner-02.jpg'), titulo: 'Commercial & Industrial', description: 'Expert Solutions for Commercial & Industrial Garage Doors & Openers' },
                { src: require('../assets/img/banner-03.jpg'), titulo: 'Maintenance & Repair', description: 'Guaranteeing Residential and Commercial Functionality and Security' },
            ],
            currentIndex: 0,
            intervalId: null,
        };
    },
    computed: {
        currentImage() {
            return this.images[this.currentIndex];
        },
    },
    methods: {
        openWhatsApp() {
            const whatsappLink = `https://api.whatsapp.com/send?phone=123456789`;
            window.open(whatsappLink, '_blank');
        },
        changeImage(index) {
            this.currentIndex = index;
            this.resetInterval();
        },
        nextImage() {
            this.currentIndex = (this.currentIndex + 1) % this.images.length;
        },
        startInterval() {
            this.intervalId = setInterval(this.nextImage, 7000); // Cambia la imagen cada 3 segundos
        },
        resetInterval() {
            clearInterval(this.intervalId);
            this.startInterval();
        },
    },
    mounted() {
        this.startInterval();
    },
};
</script>