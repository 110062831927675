<template>
    <div class="items">
        <div class="formulario" @click="modalForm = true">
            <i class="fa-solid fa-address-card"></i>
            <span>Contact Us</span>
        </div>
        <div class="redes">
            <div class="item wha"><i class="fa-brands fa-whatsapp"></i></div>
            <div class="item fac"><i class="fa-brands fa-facebook-f"></i></div>
            <div class="item twi"><i class="fa-brands fa-x-twitter"></i></div>
            <div class="item ins"><i class="fa-brands fa-instagram"></i></div>
        </div>
        <div class="modal-form" v-show="modalForm">
            <div class="cont-modal">
                <i class="fa-solid fa-square-xmark cerrar" @click="modalForm = false"></i>
                <compFormulario></compFormulario>
            </div>
        </div>
    </div>
</template>

<script>

import compFormulario from './formulario.vue'

export default {
    name: 'compRedes',
    components: {
        compFormulario
    },
    data() {
        return {
            modalForm: false,
        };
    },
};
</script>