<template>
    <div>
        <compHeader :activoMenu="activoMenu"></compHeader>
        
        
    <section id="informacion">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titulo-pricipal">
                        <img src="../../assets/img/logo-icono-color.svg">
                        <span class="azul">Broken Spring</span>
                    </div>
                    <!-- <div class="intro">
                        <strong>Welcome to CENTURION overhead doors:</strong> Elevating the Residential Experience with
                        Our Garage Door and Openers Services
                    </div> -->
                </div>
                <div class="col-12 text-welcom">
                    The service for the replacement of a broken spring on residential and commercial garage doors by Centurion Overhead Doors in Houston, Texas, stands out for offering efficient and safe solutions to restore the proper functioning of the doors. The company specializes in professionally replacing springs in both residential and commercial settings, ensuring safety and optimal performance of garage doors.
                </div>



                
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/broken/broken-1.jpg"></div>
                            <div class="titulo">Quick and Efficient Replacement</div>
                            <div class="desc">
                                Centurion Overhead Doors provides a fast and efficient service for the replacement of broken springs, minimizing downtime and restoring the operation of the garage door as quickly as possible.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/broken/broken-2.jpg"></div>
                            <div class="titulo">Quality and Durable Springs</div>
                            <div class="desc">
                                High-quality and durable springs are used to ensure long-term performance. These springs are designed to withstand continuous use and offer smooth and reliable operation.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/broken/broken-3.jpg"></div>
                            <div class="titulo">Trained Professionals</div>
                            <div class="desc">
                                The team of technicians at Centurion Overhead Doors consists of highly trained professionals in spring replacement. They ensure precise and safe installation, following industry standards.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/broken/broken-4.jpg"></div>
                            <div class="titulo">Personalized Service</div>
                            <div class="desc">
                                The company offers a personalized approach for each customer, assessing the specific needs of the door and providing tailored solutions for each situation.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/broken/broken-5.jpg"></div>
                            <div class="titulo">Satisfaction Guarantee</div>
                            <div class="desc">
                                Centurion Overhead Doors backs its spring replacement service with a customer satisfaction guarantee, ensuring the quality of the work performed.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option hidden-xs">
                    </div>
                </div>




                <div class="col-12 preguntas">
                    <div class="titulo-sec">
                        <i class="fa-solid fa-circle-question"></i>
                        Frequently Asked Questions
                    </div>
                    <div class="item-pre uno">
                        <div class="pre" @click="togglePre(1)">
                            <span>Question:* Why is it important for a professional to perform the garage door spring replacement?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preUno, 'fa-chevron-down' : !preUno }"></i>
                        </div>
                        <div class="res" v-show="preUno">
                            The replacement of garage door springs can be dangerous due to the tension involved. A trained professional has the knowledge and proper tools to perform the work safely, preventing injuries and property damage.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(2)">
                            <span>What are the risks of attempting to replace springs on my own?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preDos, 'fa-chevron-down' : !preDos }"></i>
                        </div>
                        <div class="res" v-show="preDos">
                            Attempting to replace springs without experience can result in serious injuries. The high tension of the springs can cause accidents, such as strikes or even the sudden release of tension, posing a significant safety risk.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(3)">
                            <span>What are the signs of worn-out garage door springs?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preTres, 'fa-chevron-down' : !preTres }"></i>
                        </div>
                        <div class="res" v-show="preTres">
                            Signs of worn-out springs include a door that does not lift properly, unusual noises during operation, or the presence of loose cables. If any of these issues are noticed, it is crucial to call a professional for an assessment.
                        </div>
                    </div>
                </div>

                <div class="col-12 pie">
                    Centurion Overhead Doors is committed to providing a reliable and safe service for garage door spring replacement, ensuring the peace of mind of customers in Houston, Texas.
                </div>
            </div>
        </div>
    </section>
    <compFooter></compFooter>
    </div>
</template>
  
<script>

import compHeader from '../header.vue'
import compFooter from '../footer.vue'
import compRedes from '../redes.vue'

export default {
    name: 'compBroken',
    components: {
        compHeader,
        compFooter,
        compRedes,
    },
    data() {
        return {
            activoMenu: 'repair',
            preUno: false, 
            preDos: false, 
            preTres: false, 
            preCuatro: false, 
            preCinco: false, 
        };
    },
    methods: {
        togglePre(index) {
            this.preUno = index === 1 ? !this.preUno : false;
            this.preDos = index === 2 ? !this.preDos : false;
            this.preTres = index === 3 ? !this.preTres : false;
            this.preCuatro = index === 4 ? !this.preCuatro : false;
            this.preCinco = index === 5 ? !this.preCinco : false;
        }
    }
}
</script>