<!-- src/components/Form.vue -->
<template>
  <section id="formu">
    <div class="container">
    <h1>Contact Us</h1>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <input class="form-control" type="text" id="name" v-model="formData.name" required placeholder="Name" />
      </div>
      <div class="form-group">
        <input class="form-control" type="tel" id="phone" v-model="formData.phone" required placeholder="Phone" />
      </div>
      <div class="form-group">
        <input class="form-control" type="email" id="email" v-model="formData.email" required placeholder="Email"/>
      </div>
      <div class="form-group">
        <label for="message">Message:</label>
        <textarea class="form-control" id="message" v-model="formData.message" required></textarea>
      </div>
      <button type="submit" class="btn btn-primary">Send</button>
    </form>
    <p v-if="message">{{ message }}</p>
  </div>
  </section>
</template>

<script>
export default {
  name: 'compFormulario',
  data() {
    return {
      formData: {
        name: '',
        phone: '',
        email: '',
        message: ''
      },
      message: ''
    };
  },
  methods: {
    handleSubmit() {
      fetch('http://localhost/send_email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.formData)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network error');
        }
        return response.json();
      })
      .then(data => {
        this.message = data.message;
      })
      .catch(error => {
        console.error('Error:', error);
        this.message = 'Error sending email: ' + error.message;
      });
    }
  }
};
</script>
