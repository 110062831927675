<template>
    <section id="contacto">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titulo-pricipal">
                        <img src="../assets/img/logo-icono-color.svg">
                        <span class="azul">Service </span>
                        <span class="rojo">Areas</span>
                    </div>
                    <div class="intro">
                        We cover the entire greater Houston TX area and surrounding areas
                    </div>
                </div>
                <div class="col-12 cont-contacto">
                    <div class="contacto-info">
                        <div class="item-info">
                            <i class="fa-solid fa-location-dot"></i>
                            <span>Houston TX 77073</span>
                        </div>
                        <div class="item-info ultimo">
                            <i class="fa-solid fa-phone"></i>
                            <span>(+1) 713 240 5927</span>
                        </div>
                        <div class="item-info hora">
                            <div class="tit">Working hours: </div>
                            <span>Monday to Friday: 8:00 a.m. to 6:00 p.m.</span>
                            <span>Saturday: 8:00 a.m. to 1:00 p.m.</span>
                        </div>
                        <div class="item-info redes">
                            <compRedes></compRedes>
                        </div>
                    </div>
                    <div class="contacto-mapa">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d443088.87942003453!2d-95.40129145!3d29.81717795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640b8b4488d8501%3A0xca0d02def365053b!2sHouston%2C%20Texas%2C%20EE.%20UU.!5e0!3m2!1ses-419!2smx!4v1705542611300!5m2!1ses-419!2smx"
                            width="100%" height="400" allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import compRedes from './redes.vue'

export default {
    name: 'compContacto',
    components: {
        compRedes,
    },
    data() {
        return {
        };
    },
};
</script>