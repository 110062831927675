<template>
    <div>
        <compHeader :activoMenu="activoMenu"></compHeader>
        
        
    <section id="informacion">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titulo-pricipal">
                        <img src="../../assets/img/logo-icono-color.svg">
                        <span class="azul">Panels</span>
                    </div>
                    <div class="intro">
                        Service for Replacement of Damaged Panels for Residential and Commercial Garage Doors
                    </div>
                </div>
                <div class="col-12 text-welcom">
                    Our company, Centurion Overhead Doors, offers a specialized service for the replacement of damaged panels for residential and commercial garage doors in Houston, Texas. We excel in providing effective and high-quality solutions to restore the functionality and aesthetics of affected garage doors.
                </div>



                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/panel/panel-1.jpg"></div>
                            <div class="titulo">Professional Assessment</div>
                            <div class="desc">
                                Our team conducts a detailed assessment of the damaged panels to determine the best replacement solution. We analyze the type of door, material, and other factors to ensure proper replacement.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/panel/panel-2.jpg"></div>
                            <div class="titulo">Quality Replacement Panels</div>
                            <div class="desc">
                                We use high-quality replacement panels that adhere to the exact specifications of the original door. This ensures a seamless integration and a uniform appearance.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/panel/panel-3.jpg"></div>
                            <div class="titulo">Professional Installation</div>
                            <div class="desc">
                                Our highly trained installers carry out the replacement panel installation with precision and safety, ensuring that the door functions correctly once again.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/panel/panel-4.jpg"></div>
                            <div class="titulo">Variety of Designs and Materials</div>
                            <div class="desc">
                                We offer a wide range of replacement panels in different designs and materials to accommodate the style and specific needs of each customer.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/panel/panel-5.jpg"></div>
                            <div class="titulo">Quick and Efficient Attention</div>
                            <div class="desc">
                                We respond quickly and efficiently to requests for panel replacement, minimizing disruptions and restoring functionality to the garage door in a timely manner.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option hidden-xs">
                    </div>
                </div>



                <div class="col-12 preguntas">
                    <div class="titulo-sec">
                        <i class="fa-solid fa-circle-question"></i>
                        Frequently Asked Questions
                    </div>
                    <div class="item-pre uno">
                        <div class="pre" @click="togglePre(1)">
                            <span>Can I replace the damaged panels myself?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preUno, 'fa-chevron-down' : !preUno }"></i>
                        </div>
                        <div class="res" v-show="preUno">
                            We do not recommend replacing panels without proper training. Incorrect installation can compromise the safety and operation of the door. It is crucial to rely on professionals to ensure safe and efficient installation.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(2)">
                            <span>What are the risks of not hiring a professional for panel replacement?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preDos, 'fa-chevron-down' : !preDos }"></i>
                        </div>
                        <div class="res" v-show="preDos">
                            Incorrect replacement can lead to safety issues, such as door malfunctions, injury risks, and a reduction in the effectiveness of safety features built into the door.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(3)">
                            <span>How does the door warranty get affected by non-professional panel replacement?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preTres, 'fa-chevron-down' : !preTres }"></i>
                        </div>
                        <div class="res" v-show="preTres">
                            Most garage door warranties require any service or replacement to be performed by certified professionals. DIY replacement may void the manufacturer's warranty.
                        </div>
                    </div>
                </div>
                <div class="col-12 pie">
                    By choosing Centurion Overhead Doors for the replacement of damaged panels, our customers can trust an expert service that prioritizes safety and quality at every step of the process.
                </div>
            </div>
        </div>
    </section>
    <compFooter></compFooter>
    </div>
</template>
  
<script>

import compHeader from '../header.vue'
import compFooter from '../footer.vue'
import compRedes from '../redes.vue'

export default {
    name: 'compPanels',
    components: {
        compHeader,
        compFooter,
        compRedes,
    },
    data() {
        return {
            activoMenu: 'repair',
            preUno: false, 
            preDos: false, 
            preTres: false, 
            preCuatro: false, 
            preCinco: false, 
        };
    },
    methods: {
        togglePre(index) {
            this.preUno = index === 1 ? !this.preUno : false;
            this.preDos = index === 2 ? !this.preDos : false;
            this.preTres = index === 3 ? !this.preTres : false;
            this.preCuatro = index === 4 ? !this.preCuatro : false;
            this.preCinco = index === 5 ? !this.preCinco : false;
        }
    }
}
</script>