<template>
  <section id="header">
    <compSeccHora class="hidden-xs"></compSeccHora>
    <compSeccContac></compSeccContac>
    <compSeccMenu :activoMenu="activoMenu"></compSeccMenu>
    <compChat></compChat>
  </section>
</template>

<script>

import compSeccHora from './header/horario.vue'
import compSeccContac from './header/contac.vue'
import compSeccMenu from './header/menu.vue'
import compChat from './header/chat.vue'

export default {
  name: 'compHeader',
  components: {
    compSeccHora,
    compSeccContac,
    compSeccMenu,
    compChat,
  },
  props: {
    activoMenu: String,
  },
  data() {
    return {
    };
  },
};
</script>