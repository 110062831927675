<template>
    <div>
        <compHeader :activoMenu="activoMenu"></compHeader>
        
        
    <section id="informacion">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titulo-pricipal">
                        <img src="../../assets/img/logo-icono-color.svg">
                        <span class="azul">Cable Off</span>
                    </div>
                    <div class="intro">
                        Repair or Replacement Service for Residential and Commercial Garage Door Cables
                    </div>
                </div>
                <div class="col-12 text-welcom">
                    The service for repairing or replacing cables for residential and commercial garage doors at Centurion Overhead Doors in Houston, Texas, focuses on providing efficient and secure solutions to ensure optimal door functionality. The specialized company is dedicated to addressing specific cable-related issues through either repairs or replacements to restore the proper operation of garage doors.
                </div>




                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/cable/cable-1.jpg"></div>
                            <div class="titulo">Professional Diagnosis</div>
                            <div class="desc">
                                Our team of experts conducts a thorough diagnosis to identify the root cause of the problem with the garage door cables.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/cable/cable-2.jpg"></div>
                            <div class="titulo">Effective Repairs</div>
                            <div class="desc">
                                In cases where possible, we perform precise repairs on the cables, addressing any wear, breakage, or malfunction.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/cable/cable-3.jpg"></div>
                            <div class="titulo">Cable Replacement</div>
                            <div class="desc">
                                If the cables are irreparably damaged, we proceed with the replacement using high-quality materials to ensure durability and safety.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/cable/cable-4.jpg"></div>
                            <div class="titulo">Adjustments and Testing</div>
                            <div class="desc">
                                After the repair or replacement, we adjust and test the door to ensure it functions correctly and meets safety standards.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>




                <div class="col-12 preguntas">
                    <div class="titulo-sec">
                        <i class="fa-solid fa-circle-question"></i>
                        Frequently Asked Questions
                    </div>
                    <div class="item-pre uno">
                        <div class="pre" @click="togglePre(1)">
                            <span>Can I repair or replace my garage door cables myself?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preUno, 'fa-chevron-down' : !preUno }"></i>
                        </div>
                        <div class="res" v-show="preUno">
                            :** It is not recommended. Repairing or replacing garage door cables can be dangerous and should be done by trained professionals. Incorrect handling of the cables can lead to severe injuries or additional damage to the door.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(2)">
                            <span>What are the risks of not hiring a professional to repair the door cables?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preDos, 'fa-chevron-down' : !preDos }"></i>
                        </div>
                        <div class="res" v-show="preDos">
                            Not hiring a professional can result in unsafe and inadequate repairs. Improperly installed cables can lead to door failures, cause property damage, or, worse, result in accidents that jeopardize people's safety.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(3)">
                            <span>How can I tell if my garage door cables need repair?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preTres, 'fa-chevron-down' : !preTres }"></i>
                        </div>
                        <div class="res" v-show="preTres">
                            Some signs of problems include unusual noises, difficulty in opening or closing the door, worn or visibly damaged cables. If you experience any of these issues, it is crucial to seek the assistance of a professional.
                        </div>
                    </div>
                    <div class="item-pre">
                        <div class="pre" @click="togglePre(4)">
                            <span>How long does the cable repair or replacement service take?</span>
                            <i class="fa-solid" :class="{'fa-chevron-up' : preCuatro, 'fa-chevron-down' : !preCuatro }"></i>
                        </div>
                        <div class="res" v-show="preCuatro">
                            The duration of the service depends on the severity of the problem. Our goal is to perform repairs efficiently, but safety and quality are priorities.
                        </div>
                    </div>
                </div>

                <div class="col-12 pie">
                    Centurion Overhead Doors is committed to providing cable repair or replacement services for garage doors with a focus on safety and customer satisfaction in Houston, Texas.
                </div>
            </div>
        </div>
    </section>
    <compFooter></compFooter>
    </div>
</template>
  
<script>

import compHeader from '../header.vue'
import compFooter from '../footer.vue'
import compRedes from '../redes.vue'

export default {
    name: 'compCable',
    components: {
        compHeader,
        compFooter,
        compRedes,
    },
    data() {
        return {
            activoMenu: 'repair',
            preUno: false, 
            preDos: false, 
            preTres: false, 
            preCuatro: false, 
            preCinco: false, 
        };
    },
    methods: {
        togglePre(index) {
            this.preUno = index === 1 ? !this.preUno : false;
            this.preDos = index === 2 ? !this.preDos : false;
            this.preTres = index === 3 ? !this.preTres : false;
            this.preCuatro = index === 4 ? !this.preCuatro : false;
            this.preCinco = index === 5 ? !this.preCinco : false;
        }
    }
}
</script>