<template>
    <section id="header-menu" :class="{ 'fixed-menu': scrolled }">
        <div class="container">
            <nav>
                <div class="btn-mobile hidden-lg hidden-md hidden-sm" @click="mostrarMenu = true">
                    <i class="fa-solid fa-bars"></i>
                </div>
                <div class="menu" :class="{ 'mos-menu': mostrarMenu }">
                    <div class="cerrar hidden-lg hidden-md hidden-sm" @click="mostrarMenu = false">
                        <div class="item">
                            <span>close</span>
                            <i class="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div class="logo hidden-lg hidden-md hidden-sm"><img src="../../assets/img/logo-blanco.svg" alt="">
                    </div>
                    <ul class="cont-list">
                        <li class="item-list" :class="{ 'activo': activoMenu === 'inicio' }">
                            <router-link class="enlace" to="/">
                                <span>Home</span>
                            </router-link>
                        </li>
                        <li class="item-list" :class="{ 'activo': activoMenu === 'residential' }">
                            <span @click="metodoSubUno">New Garage doors</span>
                            <i class="fa-solid fa-sort-down"></i>
                            <div class="sub" v-show="subUno">
                                <ul class="list-pri">
                                    <router-link class="sub-enlace" to="/residential"><li class="item-pri">Residential</li></router-link>
                                    <li class="item-pri">Commercial
                                        <ul class="list-sec">
                                            <router-link class="sub-enlace" to="/sectional"><li class="item-sec">Sectional Overhead Doors</li></router-link>
                                            <router-link class="sub-enlace" to="/roll-up"><li class="item-sec">Roll-Up Garage Doors</li></router-link>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="item-list" :class="{ 'activo': activoMenu === 'repair' }">
                            <span @click="metodoSubDos">Garage Doors Repair and Replacement</span>
                            <i class="fa-solid fa-sort-down"></i>
                            <div class="sub" v-show="subDos">
                                <ul class="list-pri">
                                    <router-link class="sub-enlace" to="/rollers"><li class="item-pri">Rollers and Tracks Off</li></router-link>
                                    <router-link class="sub-enlace" to="/cable"><li class="item-pri">Cable Off</li></router-link>
                                    <router-link class="sub-enlace" to="/broken"><li class="item-pri">Broken Spring</li></router-link>
                                    <router-link class="sub-enlace" to="/panels"><li class="item-pri">Panels</li></router-link>
                                    <!-- <router-link class="sub-enlace" to="/maintenance"><li class="item-pri">Garage Door Maintenance</li></router-link> -->
                                </ul>
                            </div>
                        </li>
                        <li class="item-list mante" :class="{ 'activo': activoMenu === 'openers' }">
                            <router-link class="enlace" to="/openers">
                                <span>Garage Doors Openers</span>
                            </router-link>
                        </li>
                        <li class="item-list contacto" :class="{ 'activo': activoMenu === 'contac' }">
                            <router-link class="enlace" to="/contac">
                                <span>Service Areas</span>
                            </router-link>
                        </li>
                    </ul>
                    <div class="redes hidden-lg hidden-md hidden-sm">
                        <div class="social">
                            <div class="item"><i class="fa-brands fa-facebook-f"></i></div>
                            <div class="item"><i class="fa-brands fa-x-twitter"></i></div>
                            <div class="item"><i class="fa-brands fa-instagram"></i></div>
                        </div>
                        <div class="contact">
                            <div class="item"><i class="fa-brands fa-whatsapp"></i></div>
                            <div class="item"><i class="fa-solid fa-phone"></i></div>
                            <div class="item"><i class="fa-solid fa-location-dot"></i></div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </section>
</template>

<script>

export default {
    name: 'compSeccMenu',
    components: {

    },
    props: {
        activoMenu: String,
    },
    data() {
        return {
            mostrarMenu: false,
            scrolled: false,
            subUno: false,
            subDos: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.scrolled = window.scrollY > 210;
        },
        metodoSubUno(){
            this.subUno = !this.subUno;
            this.subDos = false;
        },
        metodoSubDos(){
            this.subUno = false;
            this.subDos = !this.subDos;
        }
    },
};
</script>
