<template>
  <div>
    <compHeader :activoMenu="activoMenu"></compHeader>
    <compCarrusel></compCarrusel>
    <compInfoInicio></compInfoInicio>
    <compContacto></compContacto>
    <compFormulario></compFormulario>
    <compFooter></compFooter>
  </div>
</template>
  
<script>

import compHeader from './components/header.vue'
import compCarrusel from './components/carrusel.vue'
import compInfoInicio from './components/info-inicio.vue'
import compContacto from './components/contacto.vue'
import compFooter from './components/footer.vue'
import compFormulario from './components/formulario.vue'

export default {
  name: 'App',
  components: {
    compHeader,
    compCarrusel,
    compInfoInicio,
    compContacto,
    compFooter,
    compFormulario,
  },
  data() {
    return {
      activoMenu: 'inicio',
    };
  },
}
</script>