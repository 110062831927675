<template>
    <section id="header-contacto">
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="logo"><img src="../../assets/img/logo.svg" alt=""></div>
                    <div class="contacto">
                        <div class="ubicacion" @click="openGoogleMaps">
                            <div class="icon"><i class="fa-solid fa-location-dot"></i></div>
                            <div class="desc">
                                <div class="tit hidden-xs">You can find us at</div>
                                <div class="ubi">Houston TX 77073</div>
                            </div>
                        </div>
                        <div class="telefono" @click="makePhoneCall">
                            <div class="icon"><i class="fa-solid fa-phone"></i></div>
                            <div class="desc">
                                <div class="tit hidden-xs">Do you need services? </div>
                                <div class="tel">(+1) 713 240 5927</div>
                            </div>
                        </div>
                        <div class="whats hidden-xs">
                            <div class="cont-whats" @click="openWhatsApp">
                                <div class="icono"><i class="fa-brands fa-square-whatsapp"></i></div>
                                <div class="desc">
                                    <span class="uno">CHAT</span>
                                    <span class="dos">WITH US</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'compSeccContac',
    components: {
    },
    data() {
        return {
        };
    },
    methods: {
        openWhatsApp() {
            const whatsappLink = `https://api.whatsapp.com/send?phone=123456789`;
            window.open(whatsappLink, '_blank');
        },
        makePhoneCall() {
            window.location.href = `tel:1234567890`;
        },
        openGoogleMaps() {
            const mapsLink = `https://maps.app.goo.gl/EUECQnVUwfov4VdRA`;
            window.open(mapsLink, '_blank');
        },
    },
};
</script>
