<template>
    <div>
        <compHeader :activoMenu="activoMenu"></compHeader>
        
        
    <section id="informacion">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="titulo-pricipal">
                        <img src="../../assets/img/logo-icono-color.svg">
                        <span class="azul">Roll-Up Garage Doors</span>
                    </div>
                    <!-- <div class="intro">
                        <strong>Welcome to CENTURION overhead doors:</strong> Elevating the Residential Experience with
                        Our Garage Door and Openers Services
                    </div> -->
                </div>
                <div class="col-12 text-welcom">
                    The service of selling and installing commercial roll-up, rolling steel, and fire-rated garage doors from Centurion Overhead Doors in Houston, Texas, is characterized by providing comprehensive and high-quality solutions for businesses in the area. The company specializes in offering a diverse range of commercial garage doors that meet specific needs, emphasizing functionality, security, and compliance with industry standards.
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/roll/roll-1.jpg"></div>
                            <div class="titulo">Diverse Commercial Door Options</div>
                            <div class="desc">
                                Centurion Overhead Doors offers a variety of commercial garage doors, including roll-up, rolling steel, and fire-rated doors. This diversity allows businesses to choose doors that suit their specific requirements, whether it's for increased security, efficient space utilization, or fire protection.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/roll/roll-2.jpg"></div>
                            <div class="titulo">Industrial-Grade Materials</div>
                            <div class="desc">
                                The commercial doors provided by Centurion Overhead Doors are constructed using industrial-grade materials, ensuring durability and long-lasting performance in demanding commercial environments.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/roll/roll-3.jpg"></div>
                            <div class="titulo">Customization for Business Needs</div>
                            <div class="desc">
                                The company understands that different businesses have unique requirements. Therefore, Centurion Overhead Doors provides customization options, allowing businesses to tailor their commercial garage doors to specific dimensions, security features, and aesthetic preferences.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/roll/roll-4.jpg"></div>
                            <div class="titulo">Professional Installation Services</div>
                            <div class="desc">
                                Centurion Overhead Doors employs a team of skilled professionals experienced in the installation of commercial garage doors. The installation process is executed with precision to ensure optimal functionality and security.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/roll/roll-5.jpg"></div>
                            <div class="titulo">Fire-Rated Garage Doors</div>
                            <div class="desc">
                                For businesses that prioritize fire safety, Centurion Overhead Doors offers fire-rated garage doors designed to meet or exceed local fire safety regulations. These doors provide an added layer of protection for commercial spaces.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/roll/roll-6.jpg"></div>
                            <div class="titulo">Compliance with Codes and Standards</div>
                            <div class="desc">
                                Centurion Overhead Doors ensures that all commercial garage doors comply with relevant local building codes, safety standards, and industry regulations, offering peace of mind to business owners.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="option-services">
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/roll/roll-7.jpg"></div>
                            <div class="titulo">Prompt and Efficient Service</div>
                            <div class="desc">
                                The company is committed to providing prompt and efficient service, minimizing downtime for businesses during the installation process and ensuring that the doors meet the specified requirements.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                    <div class="item-option">
                        <div class="option">
                            <div class="foto"><img src="../../assets/img/roll/roll-8.jpg"></div>
                            <div class="titulo">Maintenance and Support</div>
                            <div class="desc">
                                Centurion Overhead Doors offers ongoing maintenance and support services for commercial garage doors, ensuring their continued smooth operation and addressing any issues that may arise.
                            </div>
                            <div class="btn-contac">
                                <compRedes></compRedes>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 pie">
                    By offering these key features, Centurion Overhead Doors aims to establish itself as a trusted provider of commercial roll-up, rolling steel, and fire-rated garage doors in the Houston, Texas business community.
                </div>
            </div>
        </div>
    </section>

        <compFooter></compFooter>
    </div>
</template>
  
<script>

import compHeader from '../header.vue'
import compFooter from '../footer.vue'
import compRedes from '../redes.vue'

export default {
    name: 'compComercial',
    components: {
        compHeader,
        compFooter,
        compRedes,
    },
    data() {
        return {
            activoMenu: 'residential',
        };
    },
}
</script>